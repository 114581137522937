import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Container from "react-bootstrap/Container";
import { useSession, signIn, signOut } from "next-auth/react";
import Link from 'next/link';
import Button from "react-bootstrap/Button";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";

export default function Layout({children}: {children: React.ReactNode})
{
  const { data: session } = useSession();

  return (
    session ? (
      <>
        <Navbar style={{padding: 10}}>
          <Link href="/">
            <Navbar.Brand>
              <Image
                width={100}
                src={
                  "https://trendskitchens.co.nz/assets/Uploads/Logos/trends-kitchens-logo.png"
                }
              />
            </Navbar.Brand>
          </Link>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <NavDropdown title={session.user?.name} id="basic-nav-dropdown">
                <NavDropdown.Item href="#action/3.1" onClick={() => signOut()}>Sign Out</NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <Container>
          {children}
        </Container>
      </>
        ) : (
          <Container>
            <Row style={{paddingTop: 50, paddingBottom: 50}}>
              <Col style={{ textAlign: "center" }}>
                <Link href="/">
                  <Image
                    src={
                      "https://trendskitchens.co.nz/assets/Uploads/Logos/trends-kitchens-logo.png"
                    }
                  />
                </Link>
              </Col>
            </Row>
            <Row>
              <Col style={{textAlign: "center"}}>
                <Button onClick={() => signIn('azure-ad')} size="lg">Sign in</Button>
              </Col>
            </Row>
      </Container>
      )
  );
}